import User from "./User";
import { Button, Modal, Form } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import React, { useState, useRef } from "react";
import './header.css';
import axios from 'axios';
import logo from '../../logo_white.png';
import * as biIcons from "react-icons/bi";
import * as giIcons from "react-icons/gi";
import * as riIcons from "react-icons/ri";
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import moment from 'moment';
import Select from 'react-select';

export default function Header() {
    const pathname = useLocation().pathname;
    const apiUrl = process.env.REACT_APP_API_URL;
    const reportUrl = process.env.REACT_APP_REPORT_URL;
    const token = localStorage.getItem("token");
    const selectInputRef = useRef();
    const [exportLCA, setExportLCA] = useState(true);
    const [exportResultsLCA, setExportResultsLCA] = useState(true);
    const [exportHotspotLCA, setExportHotspotLCA] = useState(true);
    const [exportLCC, setExportLCC] = useState(true);
    const [exportResultsLCC, setExportResultsLCC] = useState(true);
    const [exportHotspotLCC, setExportHotspotLCC] = useState(true);
    const [exportIncludeCapitalCostLCC, setExportIncludeCapitalCostLCC] = useState(true);
    const [exportSLCA, setExportSLCA] = useState(true);
    const [exportResultsSLCA, setExportResultsSLCA] = useState(true);
    const [exportHotspotSLCA, setExportHotspotSLCA] = useState(true);
    const [exportCircularity, setExportCircularity] = useState(true);
    const [exportCriticality, setExportCriticality] = useState(true);
    const [exportProductComparison, setExportProductComparison] = useState(true);
    const [exportLCSA, setExportLCSA] = useState(true);
    const [exportCustomLCSA, setExportCustomLCSA] = useState(true);
    const [products, setProducts] = useState([]);
    const [selectedComparisonProducts, setSelectedComparisonProducts] = useState([]);
    const [selectedMethods, setSelectedMethods] = useState([]);
    const [selectedCustomMethods, setSelectedCustomMethods] = useState([]);
    const [weights, setWeights] = useState("equal");
    const [customWeights, setCustomWeights] = useState("equal");
    const [pesos, setPesos] = useState({});
    const [lcaWeight, setLcaWeight] = useState(null);
    const [lccWeight, setLccWeight] = useState(null);
    const [slcaWeight, setSlcaWeight] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [selectedOptionsLCA, setSelectedOptionsLCA] = useState(null);
    const [selectedOptionsSLCAType, setSelectedOptionsSLCAType] = useState(null);
    const [selectedOptionsSLCA, setSelectedOptionsSLCA] = useState(null);
    const [lcaCategoryList, setLcaCategoryList] = useState([]);
    const [slcaCategoryList, setSlcaCategoryList] = useState([]);
    const [performanceCategoryList, setPerformanceCategoryList] = useState([]);
    const [riskCategoryList, setRiskCategoryList] = useState([]);

    const [showOOFModal, setShowOOFModal] = useState(false);
    const [showDownloadReportModal, setShowDownloadReportModal] = useState(false);
    const handleShowDownloadReportModal = async () => {
        reset();
        setShowDownloadReportModal(true);
        setValue("otherProducts", []);
        setSelectedComparisonProducts([]);
        setValue("lcsa", []);
        setSelectedMethods([]);
        setSelectedCustomMethods([]);
        setLcaWeight(null);
        setLccWeight(null);
        setSlcaWeight(null);
        setValue('weights', "equal");
        setWeights("equal");
        setExportLCSA(true);
        setExportCustomLCSA(true);
        setValue('customWeights', "equal");
        setCustomWeights("equal");
        setLcaCategoryList([]);
        setSlcaCategoryList([]);
        setRiskCategoryList([]);
        setPerformanceCategoryList([]);
        setSelectedOptions(null);
        setSelectedOptionsLCA(null);
        setSelectedOptionsSLCAType(null);
        setSelectedOptionsSLCA(null);
        setSelectedCustomMethods([]);
        setPesos({});
        await fetchProducts();
    }
    const [state, setState] = useState({
        selectedFile: null
    });
    const handleShowShowOOFModal = () => setShowOOFModal(true);

    const onFileChange = (event) => {
        setState({ selectedFile: event.target.files[0] });
    };

    const handleCloseDownloadReportModal = () => {
        setShowDownloadReportModal(false);
        setValue("otherProducts", []);
        setSelectedComparisonProducts([]);
        setValue("lcsa", []);
        setSelectedMethods([]);
        setSelectedCustomMethods([]);
        setLcaWeight(null);
        setLccWeight(null);
        setSlcaWeight(null);
    };

    const handleCloseShowOOFModal = () => {
        setState({ selectedFile: null });
        setShowOOFModal(false);
    };

    const handleChangeLCA = (data) => {
        setSelectedOptionsLCA(data);
        setValue('domainLca', data);
        setPesos({});
        setValue('pesos', {});
    };

    const handleChangeSLCA = (data) => {
        setSelectedOptionsSLCA(data);
        setValue('domainSlca', data);
        setPesos({});
        setValue('pesos', {});
    };

    const handleChangeSLCAType = (data) => {
        setSelectedOptionsSLCAType(data);
        if (data.value === 'Risk assesment') {
            setSlcaCategoryList(riskCategoryList);
        } else {
            setSlcaCategoryList(performanceCategoryList);
        }
        setPesos({});
        setValue('pesos', {});
        setSelectedOptionsSLCA(null);
        if (selectInputRef.current) {
            selectInputRef.current.clearValue();
        }
    };

    const downloadModelSchema = yup.object({
        showLCA: yup.boolean().required(),
        showResultsLCA: yup.boolean().required(),
        showHotspotLCA: yup.boolean().required(),
        showLCC: yup.boolean().required(),
        showIncludeCapitalCostLCC: yup.boolean().required(),
        showResultsLCC: yup.boolean().required(),
        showHotspotLCC: yup.boolean().required(),
        showSLCA: yup.boolean().required(),
        showResultsSLCA: yup.boolean().required(),
        showHotspotSLCA: yup.boolean().required(),
        showCircularity: yup.boolean().required(),
        showCriticality: yup.boolean().required(),
        showProductComparison: yup.boolean().required(),
        showLCSA: yup.boolean().required(),
        showCustomLCSA: yup.boolean().required(),
        otherProducts: yup.array().when(['showLCSA', 'showCustomLCSA', 'showProductComparison'], {
            is: (value, value2, value3) => value === true || value2 === true || value3 === true,
            then: (schema) => schema.min(1, 'You must select at least one product to compare').required('You must select at least one product to compare'),
            otherwise: (schema) => schema,
        }),
        lcsa: yup.array().when(['showLCSA'], {
            is: (value) => value === true,
            then: (schema) => schema.min(1, 'You must select at least one MDCA method').required('You must select at least one MDCA method'),
            otherwise: (schema) => schema,
        }),
        weights: yup.string().required(),
        weight_env: yup.number().when(['weights'], {
            is: (value) => value === "custom",
            then: (schema) => schema.required("You must fill custom weights"),
            otherwise: (schema) => schema,
        }),
        weight_eco: yup.number().when(['weights'], {
            is: (value) => value === "custom",
            then: (schema) => schema.required("You must fill custom weights"),
            otherwise: (schema) => schema,
        }),
        weight_soc: yup.number().when(['weights'], {
            is: (value) => value === "custom",
            then: (schema) => schema.required("You must fill custom weights"),
            otherwise: (schema) => schema,
        }),
        weight_total: yup.number().when(['weight_env', 'weight_eco', 'weight_soc'], {
            is: (value, value2, value3) => !isNaN(value) && !isNaN(value2) && !isNaN(value3) && value + value2 + value3 !== 1,
            then: (schema) => schema.required("The sum has to be 1"),
            otherwise: (schema) => schema,
        }),
        domains: yup.array().when(['showCustomLCSA'], {
            is: (value) => value === true,
            then: (schema) => schema.min(1, 'You must select at least one domain').required('You must select at least one domain'),
            otherwise: (schema) => schema,
        }),
        domainLca: yup.array().when(['showCustomLCSA', 'domains'], {
            is: (value, value2) => value === true && (typeof value2 !== 'undefined') && value2 !== null && value2.length > 0 && value2.includes(value2.find(el => el.value === 'lca')),
            then: (schema) => schema.min(1, 'You must select at least one category').required('You must select at least one category'),
            otherwise: (schema) => schema,
        }),
        domainSlca: yup.array().when(['showCustomLCSA', 'domains'], {
            is: (value, value2) => value === true && (typeof value2 !== 'undefined') && value2 !== null && value2.length > 0 && value2.includes(value2.find(el => el.value === 'slca')),
            then: (schema) => schema.min(1, 'You must select at least one category').required('You must select at least one category'),
            otherwise: (schema) => schema,
        }),
        categories_total: yup.number().when(['showCustomLCSA', 'domains', 'domainLca', 'domainSlca'], {
            is: (value, value2, value3, value4) => value === true && (typeof value2 !== 'undefined') && value2 !== null && ((typeof value3 !== 'undefined' && value3 !== null) || (typeof value4 !== 'undefined' && value4 !== null)) && sumCategories(value2, typeof value3 !== 'undefined' && value3 !== null && value3.length > 0 ? value3 : [], selectedOptions !== null && selectedOptions.filter(e => e.value === 'lcc').length > 0 ? optionsLCC : [], typeof value4 !== 'undefined' && value4 !== null && value4.length > 0 ? value4 : []) < 2,
            then: (schema) => schema.required("You must choose at least 2 categories in total"),
            otherwise: (schema) => schema,
        }),
        customLcsa: yup.array().when(['showCustomLCSA'], {
            is: (value) => value === true,
            then: (schema) => schema.min(1, 'You must select at least one MDCA method').required('You must select at least one MDCA method'),
            otherwise: (schema) => schema,
        }),
        customWeights: yup.string().required(),
        custom_weight_total: yup.number().when(['pesos', 'showCustomLCSA', 'customWeights'], {
            is: (value, value2, value3) => value2 === true && value3 === 'custom' && (typeof(value) === 'undefined' || (typeof(value) !== 'undefined' && Object.values(value).reduce((a, b) => a + parseFloat(b), 0) !== 1)),
            then: (schema) => schema.required("The sum has to be 1"),
            otherwise: (schema) => schema,
        }),
    })

    const onFileUpload = async () => {
        if (state.selectedFile !== null) {
            document.getElementById("splash").style.display = "block";
            const data = await state.selectedFile.arrayBuffer();
            const workbook = XLSX.read(data, { cellFormula: true, sheetStubs: true, cellStyles: true });

            const worksheet = workbook.Sheets["All Results"];
            const jsonObject = XLSX.utils.sheet_to_json(worksheet, { raw: false, defval: "" });
            axios.post(`${apiUrl}/products`, jsonObject, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }).then(() => {
                handleCloseShowOOFModal();
                document.getElementById("splash").style.display = "none";

                if (pathname === "/general-info") {
                    window.location.reload(false);
                }
                toast.success('Product successfully uploaded!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            }).catch(err => {
                let text = "";
                if (err.response.data.detail === "Date.IsNotWellFormatted") {
                    text = "Date is not well formatted"
                } else if (err.response.data.detail === "Scenario.NotExists") {
                    text = "Scenario not exists"
                } else {
                    text = err.response.data.detail;
                }
                toast.error(text, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                document.getElementById("splash").style.display = "none";
            });
        }
    };

    const fetchLcaData = async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/lca`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data.filter(e => e.weighValue !== null) };
        } catch (err) {
            console.log(err);
        }
    }

    const fetchSelectedSlcaRiskDatabaseData = async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/slca/risk/databases`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return res.data;
        } catch (err) {
            console.log(err);
        }
    };

    const fetchSelectedSlcaRiskData = async (id, database) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/slca/risk/databases/${database}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data };
        } catch (err) {
            console.log(err);
        }
    };

    const fetchSlcaPerformanceData = async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/slca/performance`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data };
        } catch (err) {
            console.log(err);
        }
    };

    const fetchSelectedLcsaData = async (id) => {
        try {
            const res = await axios.get(`${apiUrl}/products/${id}/lcsa`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return { id: id, data: res.data };
        } catch (err) {
            console.log(err);
        }
    };

    const getData = async () => {
        const array = [localStorage.getItem("product"), ...selectedComparisonProducts];
        const lcas = [];
        const lccs = [];
        const performances = [];
        const risks = [];
        const totals = [];
        for (let i = 0; i < array.length; i++) {
            const result = await fetchLcaData(array[i]);
            const lcc = await fetchSelectedLcsaData(array[i]);
            const performance = await fetchSlcaPerformanceData(array[i]);
            const risk = await fetchSelectedSlcaRiskDatabaseData(array[i]);
            lcas.push(result);
            lccs.push(lcc);
            performances.push(performance);
            for (let j = 0; j < risk.length; j++) {
                totals.push(risk[j].database);
            }
        }
        const counts = {};
        const databases = [];
        totals.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
        if ((counts["SHDB v5"] !== undefined && counts["SHDB v5"] === array.length) || (counts["PSILCA"] !== undefined && counts["PSILCA"] === array.length) ||
            (counts["RepRisk"] !== undefined && counts["RepRisk"] === array.length) || (counts["Others"] !== undefined && counts["Others"] === array.length)) {
            if (counts["SHDB v5"] !== undefined && counts["SHDB v5"] === array.length) {
                databases.push("SHDB v5");
            }
            if (counts["PSILCA"] !== undefined && counts["PSILCA"] === array.length) {
                databases.push("PSILCA");
            }
            if (counts["RepRisk"] !== undefined && counts["RepRisk"] === array.length) {
                databases.push("RepRisk");
            }
            if (counts["Others"] !== undefined && counts["Others"] === array.length) {
                databases.push("Others");
            }
        }
        await getLcaCategoryList(lcas);
        await getSlcaPerformanceList(performances);
        if (databases.length === 1) {
            for (let i = 0; i < array.length; i++) {
                const riskData = await fetchSelectedSlcaRiskData(array[i], databases[0]);
                risks.push(riskData);
            }
            await getSlcaCategoryList(risks);
        }
    };

    const methods = [
        { value: "SAW", label: "SAW" },
        { value: "MAVT", label: "MAVT" },
        { value: "TOPSIS", label: "TOPSIS" }
    ];

    const options = [
        { value: 'lca', label: 'LCA' },
        { value: 'lcc', label: 'LCC' },
        { value: 'slca', label: 'S-LCA' }
    ];

    const optionsLCC = [
        { value: 'Total undiscounted costs', label: 'Total undiscounted costs' }
    ];

    const slcaType = [
        { value: 'Performance assesment', label: 'Performance assesment' },
        { value: 'Risk assesment', label: 'Risk assesment' }
    ]

    const handleChange = (data) => {
        setSelectedOptions(data);
        setValue('domains', data);
        if (data.filter(e => e.value === 'slca').length === 0) {
            setSelectedOptionsSLCA(null);
            setSelectedOptionsSLCAType(null);
        }
    };

    const toggleBar = () => {
        const wrapper = document.querySelector(".wrapper");
        if (wrapper.classList.contains('hide')) {
            wrapper.classList.remove("hide");
        } else {
            wrapper.classList.add("hide");
        }
    }

    const { handleSubmit, register, setValue, reset, formState: { errors } } = useForm({
        resolver: yupResolver(downloadModelSchema)
    });

    const downloadReport = data => {
        document.getElementById("splash").style.display = "block";
        const token = localStorage.getItem("token");
        let decodedToken = null;
        if (token) {
            decodedToken = jwtDecode(token);
        }
        const payload = {
            user: decodedToken.email,
            product: localStorage.getItem("product"),
            showLCA: data.showLCA,
            showResultsLCA: data.showResultsLCA,
            showHotspotLCA: data.showHotspotLCA,
            showLCC: data.showLCC,
            includeCapitalCostLCC: data.showIncludeCapitalCostLCC,
            showResultsLCC: data.showResultsLCC,
            showHotspotLCC: data.showHotspotLCC,
            showSLCA: data.showSLCA,
            showCircularity: data.showCircularity,
            showCriticality: data.showCriticality,
            showProductComparison: data.showProductComparison,
            showLCSA: data.showLCSA,
            otherProducts: selectedComparisonProducts.length > 0 ? selectedComparisonProducts.map(p => p.value) : [],
            lcsa: selectedMethods.length > 0 ? selectedMethods.map(m => m.value) : [],
            weights: weights,
            weight_env: lcaWeight,
            weight_eco: lccWeight,
            weight_soc: slcaWeight,
            showCustomLCSA: data.showCustomLCSA,
            customWeights: customWeights,
            customMDCAS: selectedCustomMethods.length > 0 ? selectedCustomMethods.map(m => m.value) : [],
            customLcsa: {
                env: selectedOptionsLCA !== null && selectedOptionsLCA.length > 0 ? selectedOptionsLCA.map((e, index) => ({
                    name: e.value,
                    weight: customWeights === 'custom' ? parseFloat(pesos[index]) : 0
                })) : [],
                eco: selectedOptions !== null && selectedOptions.filter(e => e.value === 'lcc').length > 0 ? optionsLCC.map(e => ({
                    name: "Total Undiscounted Costs",
                    weight: customWeights === 'custom' ? parseFloat(selectedOptionsLCA !== null && selectedOptionsLCA.length > 0 ? pesos[selectedOptionsLCA.length] : 0) : 0
                })) : [],
                soc: {
                    risk: selectedOptionsSLCAType !== null && selectedOptionsSLCAType.value === 'Risk assesment' && selectedOptionsSLCA !== null && selectedOptionsSLCA.length > 0 ? selectedOptionsSLCA.map((e, index) => ({
                        name: e.value,
                        weight: customWeights === 'custom' ? parseFloat(pesos[index] + (selectedOptionsLCA !== null && selectedOptionsLCA.length > 0 ? pesos[selectedOptionsLCA.length] : 0) + (selectedOptions !== null && selectedOptions.filter(e => e.value === 'lcc').length > 0 ? 1 : 0)) : 0
                    })) : [],
                    performance: selectedOptionsSLCAType !== null && selectedOptionsSLCAType.value === 'Performance assesment' && selectedOptionsSLCA !== null && selectedOptionsSLCA.length > 0 ? selectedOptionsSLCA.map((e, index) => ({
                        name: e.value,
                        weight: customWeights === 'custom' ? parseFloat(pesos[index] + (selectedOptionsLCA !== null && selectedOptionsLCA.length > 0 ? pesos[selectedOptionsLCA.length] : 0) + (selectedOptions !== null && selectedOptions.filter(e => e.value === 'lcc').length > 0 ? 1 : 0)) : 0
                    })) : []
                }
            }
        };
        axios.post(`${reportUrl}/create-report`, payload, { responseType: 'blob' })
            .then((response) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `Report-${moment().format("YYYY-MM-DD HH:mm:ss")}.pdf`,
                );
                document.body.appendChild(link);

                // Start download
                link.click();
                link.parentNode.removeChild(link);
                handleCloseDownloadReportModal();
                document.getElementById("splash").style.display = "none";
            }).catch(err => {
                toast.error(err.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                document.getElementById("splash").style.display = "none";
            });
    }

    const changeExportProductComparison = () => {
        setExportProductComparison(!exportProductComparison);
    }

    const changeExportLCSA = () => {
        setExportLCSA(!exportLCSA);
    }

    const changeExportCustomLCSA = () => {
        setExportCustomLCSA(!exportCustomLCSA);
    }

    const changeExportLCA = () => {
        setExportLCA(!exportLCA);
    }

    const changeExportLCC = () => {
        setExportLCC(!exportLCC);
    }

    const changeExportSLCA = () => {
        setExportSLCA(!exportSLCA);
    }

    const addComparisonProducts = async (o) => {
        setValue("otherProducts", o);
        setSelectedComparisonProducts(o);
        await getData();
    }

    const addSelectedMethod = (o) => {
        setValue("lcsa", o);
        setSelectedMethods(o);
    }

    const addSelectedCustomMethod = (o) => {
        setValue("customLcsa", o);
        setSelectedCustomMethods(o);
    }

    const changeWeight = (value) => {
        setWeights(value)
        setValue("weights", value);
    }

    const changeCustomWeight = (value) => {
        setCustomWeights(value)
        setValue("customWeights", value);
        setPesos({});
    }

    const getLcaCategoryList = (lcas) => {
        const categories = [];
        for (let i = 0; i < lcas.length; i++) {
            const lca = lcas[i];
            const data = lca.data;
            for (let j = 0; j < data.length; j++) {
                const category = data[j].category;
                if (categories.indexOf(category) === -1) {
                    categories.push({ value: category, label: category });
                }
            }
        }
        categories.sort(function (a, b) {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
        categories.forEach(function (item, index, object) {
            if (categories.filter(val => val.value === item.value)) {
                object.splice(index, 1);
            }
        });
        setLcaCategoryList(categories);
    }

    const getSlcaCategoryList = (slcas) => {
        const categories = [];
        const cats = [];
        for (let i = 0; i < slcas.length; i++) {
            const newDataOthers = slcas[i].data.map(c => c.items).flat(2);
            for (let j = 0; j < newDataOthers.length; j++) {
                categories.push(newDataOthers[j].topic);
            }
        }

        const counts = {};
        categories.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
        const entriesList = Object.entries(counts);
        const filteredCategories = entriesList.filter(([_, val]) => val === slcas.length).map(([key, _]) => key);

        filteredCategories.sort((a, b) => a.localeCompare(b));
        const newCategories = [...new Set(filteredCategories)];
        for (let j = 0; j < newCategories.length; j++) {
            const cat = newCategories[j];
            cats.push({ value: cat, label: cat });
        }
        setRiskCategoryList(cats);
    }

    const sumCategories = (options, lca, lcc, slca) => {
        let sum = 0;
        if (options.filter(e => e.value === 'lca').length > 0 && lca !== null && lca.length > 0) {
            sum += lca.length;
        }
        if (options.filter(e => e.value === 'lcc').length > 0 && lcc !== null && lcc.length > 0) {
            sum += lcc.length;
        }
        if (options.filter(e => e.value === 'slca').length > 0 && slca !== null && slca.length > 0) {
            sum += slca.length;
        }
        return sum;
    }

    const getSlcaPerformanceList = (slcas) => {
        const categories = [];
        const cats = [];
        for (let i = 0; i < slcas.length; i++) {
            for (let j = 0; j < slcas[i].data.length; j++) {
                for (let k = 0; k < slcas[i].data[j].items.length; k++) {
                    categories.push(`${slcas[i].data[j].stakeholder} - ${slcas[i].data[j].items[k].topic}`);
                }
            }
        }

        const counts = {};
        categories.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
        const entriesList = Object.entries(counts);
        const filteredCategories = entriesList.filter(([_, val]) => val === slcas.length).map(([key, _]) => key);

        filteredCategories.sort((a, b) => a.localeCompare(b));
        const newCategories = [...new Set(filteredCategories)];
        for (let j = 0; j < newCategories.length; j++) {
            const cat = newCategories[j];
            cats.push({ value: cat, label: cat });
        }
        setPerformanceCategoryList(cats);
    }

    const fetchProducts = async () => {
        axios.get(`${apiUrl}/products`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            const prds = [];
            const orderProducts = response.data.filter(p => p.id !== localStorage.getItem("product"));
            orderProducts.forEach(p => {
                prds.push({ value: p.id, label: `${p.productName} - ${p.scenario}` });
            });
            prds.sort((a, b) => a.label.localeCompare(b.label))
            setProducts(prds);
        }).catch(err => {
            console.log(err);
        });
    }

    const download = () => {
        axios({
            url: `${apiUrl}/products/download-template`, //your url
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            responseType: 'blob', // important
        }).then((response) => {
            setValue("otherProducts", []);
            setSelectedComparisonProducts([]);
            setValue("lcsa", []);
            setSelectedMethods([]);
            setSelectedCustomMethods([]);
            setLcaWeight(null);
            setLccWeight(null);
            setSlcaWeight(null);
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'OOF_FINAL_VERSION.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    }

    const downloadExample1 = () => {
        axios({
            url: `${apiUrl}/products/download-example1`, //your url
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            responseType: 'blob', // important
        }).then((response) => {
            setValue("otherProducts", []);
            setSelectedComparisonProducts([]);
            setValue("lcsa", []);
            setSelectedMethods([]);
            setSelectedCustomMethods([]);
            setLcaWeight(null);
            setLccWeight(null);
            setSlcaWeight(null);
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'OOF_FINAL_VERSION_finely_chopped_tomato.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    }

    const downloadExample2 = () => {
        axios({
            url: `${apiUrl}/products/download-example2`, //your url
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            responseType: 'blob', // important
        }).then((response) => {
            setValue("otherProducts", []);
            setSelectedComparisonProducts([]);
            setValue("lcsa", []);
            setSelectedMethods([]);
            setSelectedCustomMethods([]);
            setLcaWeight(null);
            setLccWeight(null);
            setSlcaWeight(null);
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'OOF_FINAL_VERSION_diced_tomato.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    }

    return (
        <>
            <Modal show={showOOFModal} onHide={handleCloseShowOOFModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Access to OOF</Modal.Title>
                </Modal.Header>
                <Modal.Body className="upload-file">
                    <div className="title">
                        Upload filled OOF
                    </div>
                    <div className="input-group mb-3">
                        <label htmlFor="file" className="input-group-text btn choose">Choose</label>
                        <span className="form-control text">{state.selectedFile ? state.selectedFile.name : "No file chosen"}</span>
                        <input id="file" type="file" onChange={onFileChange} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                        <Button className="input-group-text" variant="primary" onClick={onFileUpload}>
                            Upload! <biIcons.BiSolidCloudUpload />
                        </Button>
                    </div>
                    <div className="title mt-4" >
                        Download OOF template
                    </div>
                    <Button variant="primary" onClick={download}>Download <biIcons.BiSolidCloudDownload /></Button>
                    <div className="title mt-4" >
                        Download examples
                    </div>
                    <Button variant="secondary" className="mb-1" onClick={downloadExample1}>Download Example 1 <biIcons.BiSolidCloudDownload /></Button>
                    <br/>
                    <Button variant="secondary" onClick={downloadExample2}>Download Example 2 <biIcons.BiSolidCloudDownload /></Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="close" variant="secondary" onClick={handleCloseShowOOFModal}>
                        Close<riIcons.RiCloseFill />
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="xl" show={showDownloadReportModal} onHide={handleCloseDownloadReportModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Download report</Modal.Title>
                </Modal.Header>
                <Modal.Body className="download-report">
                    <label className="label">Select the information you want to include in the report: </label>
                    <Form className="form mt-3" noValidate onSubmit={handleSubmit(downloadReport)} id="downloadForm">
                        <Form.Group className="mb-3">
                            <div className="mb-1">
                                <Form.Check
                                    type="checkbox"
                                    defaultChecked={true}
                                    value={exportLCA}
                                    {...register("showLCA")}
                                    onChange={changeExportLCA}
                                    label='LCA'
                                />
                                {
                                    exportLCA &&
                                    <>
                                        <Form.Check
                                            className="ms-4"
                                            type="checkbox"
                                            defaultChecked={true}
                                            value={exportResultsLCA}
                                            {...register("showResultsLCA")}
                                            onChange={(e) => { setExportResultsLCA(e.currentTarget.value) }}
                                            label='Results'
                                        />
                                        <Form.Check
                                            className="ms-4"
                                            type="checkbox"
                                            defaultChecked={true}
                                            value={exportHotspotLCA}
                                            {...register("showHotspotLCA")}
                                            onChange={(e) => { setExportHotspotLCA(e.currentTarget.value) }}
                                            label='Hotspot'
                                        />
                                    </>
                                }
                            </div>
                            <div className="mb-1">
                                <Form.Check
                                    type="checkbox"
                                    defaultChecked={true}
                                    defaultValue={exportLCC}
                                    {...register("showLCC")}
                                    onChange={changeExportLCC}
                                    label='LCC'
                                />
                                {
                                    exportLCC &&
                                    <>
                                        <Form.Check
                                            className="ms-4"
                                            type="checkbox"
                                            defaultChecked={true}
                                            value={exportIncludeCapitalCostLCC}
                                            {...register("showIncludeCapitalCostLCC")}
                                            onChange={(e) => { setExportIncludeCapitalCostLCC(e.currentTarget.value) }}
                                            label='Include Capital Cost'
                                        />
                                        <Form.Check
                                            className="ms-4"
                                            type="checkbox"
                                            defaultChecked={true}
                                            value={exportResultsLCC}
                                            {...register("showResultsLCC")}
                                            onChange={(e) => { setExportResultsLCC(e.currentTarget.value) }}
                                            label='Results'
                                        />
                                        <Form.Check
                                            className="ms-4"
                                            type="checkbox"
                                            defaultChecked={true}
                                            value={exportHotspotLCC}
                                            {...register("showHotspotLCC")}
                                            onChange={(e) => { setExportHotspotLCC(e.currentTarget.value) }}
                                            label='Hotspot'
                                        />
                                    </>
                                }
                            </div>
                            <div className="mb-1">
                                <Form.Check
                                    type="checkbox"
                                    defaultChecked={true}
                                    value={exportSLCA}
                                    {...register("showSLCA")}
                                    onChange={changeExportSLCA}
                                    label='S-LCA'
                                />
                                {
                                    exportSLCA &&
                                    <>
                                        <Form.Check
                                            inline
                                            className="ms-4"
                                            type="checkbox"
                                            defaultChecked={true}
                                            value={exportResultsSLCA}
                                            {...register("showResultsSLCA")}
                                            onChange={(e) => { setExportResultsSLCA(e.currentTarget.value) }}
                                            label='Results'
                                        />
                                        <Form.Check
                                            className="ms-4"
                                            type="checkbox"
                                            defaultChecked={true}
                                            value={exportHotspotSLCA}
                                            {...register("showHotspotSLCA")}
                                            onChange={(e) => { setExportHotspotSLCA(e.currentTarget.value) }}
                                            label='Hotspot'
                                        />
                                    </>
                                }
                            </div>
                            <Form.Check
                                type="checkbox"
                                defaultChecked={true}
                                value={exportCircularity}
                                {...register("showCircularity")}
                                onChange={(e) => { setExportCircularity(e.currentTarget.value) }}
                                label='Circularity'
                            />
                            <Form.Check
                                type="checkbox"
                                defaultChecked={true}
                                value={exportCriticality}
                                {...register("showCriticality")}
                                onChange={(e) => { setExportCriticality(e.currentTarget.value) }}
                                label='Criticality'
                            />
                            <hr />
                            <Form.Check
                                type="checkbox"
                                defaultChecked={true}
                                value={exportProductComparison}
                                {...register("showProductComparison")}
                                onChange={changeExportProductComparison}
                                label='Product comparison'
                            />
                            <Form.Check
                                type="checkbox"
                                defaultChecked={true}
                                value={exportLCSA}
                                {...register("showLCSA")}
                                onChange={changeExportLCSA}
                                label='LCSA'
                            />
                            <Form.Check
                                type="checkbox"
                                defaultChecked={true}
                                value={exportCustomLCSA}
                                {...register("showCustomLCSA")}
                                onChange={changeExportCustomLCSA}
                                label='Custom LCSA (needs to select at least one product to compare)'
                            />
                            {(exportProductComparison || exportLCSA || exportCustomLCSA) &&
                                <>
                                    <label className="form-label mt-2">Select from the following list of products those you want to compare / integrate with the current product</label>
                                    <Select className="select-form" isMulti
                                        options={products}
                                        value={selectedComparisonProducts}
                                        onChange={async (o) => await addComparisonProducts(o)}
                                        isOptionDisabled={() => selectedComparisonProducts.length >= 2} />
                                    {errors.otherProducts && (
                                        <p className="error-message">{errors.otherProducts.message}</p>
                                    )}
                                </>
                            }
                            {exportLCSA &&
                                <>
                                    <h6 className="mt-4">LCSA</h6>
                                    <label className="mt-1 form-label">Select MCDA method</label>
                                    <Select className="select-form" isMulti
                                        options={methods}
                                        value={selectedMethods}
                                        onChange={(o) => addSelectedMethod(o)} />
                                    {errors.lcsa && (
                                        <p className="error-message">{errors.lcsa.message}</p>
                                    )}
                                    <label className="mt-3 form-label">Weights</label>
                                    <Form.Select className="select-form" onChange={e => changeWeight(e.target.value)}>
                                        <option value="equal">Equal</option>
                                        <option value="custom">Custom</option>
                                    </Form.Select>
                                    {weights === "custom" &&
                                        <>
                                            <div className="row mt-3">
                                                <label className="mt-3 form-label">Custom weights</label>
                                                <div className="col-md-4">
                                                    <Form.Control
                                                        min="0.01" max="0.98"
                                                        step="0.01" type="number" placeholder="ENV"
                                                        onChange={({ target: { value } }) => {
                                                            if (value >= 0 && value <= 1) {
                                                                setLcaWeight(value);
                                                                setValue("weight_env", value);
                                                            } else if (value > 1) {
                                                                setLcaWeight(1);
                                                                setValue("weight_env", value);
                                                            } else if (value < 0) {
                                                                setLcaWeight(0);
                                                                setValue("weight_env", value);
                                                            }
                                                        }} />
                                                </div>
                                                <div className="col-md-4">
                                                    <Form.Control
                                                        min="0.01" max="0.98" step="0.01"
                                                        type="number" placeholder="ECO"
                                                        onChange={({ target: { value } }) => {
                                                            if (value >= 0 && value <= 1) {
                                                                setLccWeight(value);
                                                                setValue("weight_eco", value);
                                                            } else if (value > 1) {
                                                                setLccWeight(1);
                                                                setValue("weight_eco", value);
                                                            } else if (value < 0) {
                                                                setLccWeight(0);
                                                                setValue("weight_eco", value);
                                                            }
                                                        }} />
                                                </div>
                                                <div className="col-md-4">
                                                    <Form.Control
                                                        min="0.01" max="0.98" step="0.01"
                                                        type="number" placeholder="SOC"
                                                        onChange={({ target: { value } }) => {
                                                            if (value >= 0 && value <= 1) {
                                                                setSlcaWeight(value);
                                                                setValue("weight_soc", value);
                                                            } else if (value > 1) {
                                                                setSlcaWeight(1);
                                                                setValue("weight_soc", value);
                                                            } else if (value < 0) {
                                                                setSlcaWeight(0);
                                                                setValue("weight_soc", value);
                                                            }
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    {
                                                        (() => {
                                                            if (errors.weight_env) {
                                                                return (<p className="error-message">{errors.weight_env.message}</p>)
                                                            } else if (errors.weight_eco) {
                                                                return (<p className="error-message">{errors.weight_eco.message}</p>)
                                                            } else if (errors.weight_soc) {
                                                                return (<p className="error-message">{errors.weight_soc.message}</p>)
                                                            } else if (errors.weight_total) {
                                                                return (<p className="error-message">{errors.weight_total.message}</p>)
                                                            }
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                            {exportCustomLCSA && selectedComparisonProducts.length > 0 &&
                                <>
                                    <h6 className="mt-4">Custom LCSA</h6>
                                    <div className="row mt-3">
                                        <div className="col">
                                            Please select the domains you want to include in the integration:
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col">
                                            <Select className="select-form" isMulti options={options} onChange={handleChange} />
                                            {errors.domains && (
                                                <p className="error-message">{errors.domains.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        {
                                            selectedOptions !== null && selectedOptions.filter(e => e.value === 'lca').length > 0 &&
                                            <div className="col-md-4">
                                                <label className="form-label form-label-sm">LCA</label>
                                                <Select isOptionDisabled={() => selectedOptionsLCA !== null && selectedOptionsLCA.length >= 3}
                                                    className="select-form"
                                                    isMulti
                                                    options={lcaCategoryList}
                                                    onChange={handleChangeLCA} />
                                                {errors.domainLca && (
                                                    <p className="error-message">{errors.domainLca.message}</p>
                                                )}
                                            </div>
                                        }
                                        {
                                            selectedOptions !== null && selectedOptions.filter(e => e.value === 'lcc').length > 0 &&
                                            <div className="col-md-4">
                                                <label className="form-label form-label-sm">LCC</label>
                                                <Select isDisabled={true} isMulti defaultValue={optionsLCC[0]} className="select-form" options={optionsLCC} />
                                            </div>
                                        }
                                        {
                                            selectedOptions !== null && selectedOptions.filter(e => e.value === 'slca').length > 0 &&
                                            <div className="col-md-4">
                                                <label className="form-label form-label-sm">S-LCA</label>
                                                <Select
                                                    className="select-form"
                                                    options={slcaType}
                                                    onChange={handleChangeSLCAType} />
                                                {
                                                    selectedOptionsSLCAType !== null && <Select ref={selectInputRef} isOptionDisabled={() => selectedOptionsSLCA !== null && selectedOptionsSLCA.length >= 3}
                                                        className="select-form mt-3"
                                                        isMulti
                                                        options={slcaCategoryList}
                                                        onChange={handleChangeSLCA} />
                                                }
                                                {errors.domainSlca && (
                                                    <p className="error-message">{errors.domainSlca.message}</p>
                                                )}
                                            </div>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            {errors.domainLca == null && errors.domainSlca == null && errors.categories_total && (
                                                <p className="error-message">{errors.categories_total.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    {
                                        selectedOptions !== null && ((selectedOptions.filter(e => e.value === 'lca').length > 0 && selectedOptionsLCA !== null && selectedOptionsLCA.length > 0) ||
                                            (selectedOptions.filter(e => e.value === 'lcc').length > 0 && optionsLCC !== null && optionsLCC.length > 0) ||
                                            (selectedOptions.filter(e => e.value === 'slca').length > 0 && selectedOptionsSLCA !== null && selectedOptionsSLCA.length > 0)) &&
                                        (sumCategories(selectedOptions, selectedOptionsLCA, optionsLCC, selectedOptionsSLCA) >= 2) &&
                                        <>
                                            <div className="row mt-3">
                                                <div className="col">
                                                    Which MCDA method would you like to apply to aggregate the LCSA results?
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-md-6">
                                                    <Select className="select-form" isMulti
                                                        options={methods}
                                                        value={selectedCustomMethods}
                                                        onChange={(o) => addSelectedCustomMethod(o)} />
                                                    {errors.customLcsa && (
                                                        <p className="error-message">{errors.customLcsa.message}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <label className="form-label">Weights</label>
                                                    <Form.Select className="select-form" onChange={e => changeCustomWeight(e.target.value)}>
                                                        <option value="equal">Equal</option>
                                                        <option value="custom">Custom</option>
                                                    </Form.Select>
                                                    {errors.customWeights && (
                                                        <p className="error-message">{errors.customWeights.message}</p>
                                                    )}
                                                </div>
                                            </div>
                                            {customWeights === "custom" &&
                                                <>
                                                    <div className="row mt-3">
                                                        {(() => {
                                                            let i = 0;
                                                            const controls = [];
                                                            const controlsWithCol = [];
                                                            if (typeof (selectedOptionsLCA) !== 'undefined' && selectedOptionsLCA != null && selectedOptionsLCA.length > 0) {
                                                                selectedOptionsLCA.forEach(e => {
                                                                    controls.push(
                                                                        <Form.Control className={`mt-1 index-${i}`}
                                                                            min="0.01" max="0.98" step="0.01"
                                                                            type="number" placeholder={e.value}
                                                                            value={pesos[`${i}`] || ''}
                                                                            onChange={(e) => {
                                                                                let idx = 0;
                                                                                e.target.classList.forEach(c => {
                                                                                    if (c.includes('index-')) {
                                                                                        idx = c.split('-')[1];
                                                                                    }
                                                                                });
                                                                                const val = e.target.value;
                                                                                if (!isNaN(val) && val >= 0 && val <= 1) {
                                                                                    setPesos({ ...pesos, [`${idx}`]: val });
                                                                                    setValue("pesos", { ...pesos, [`${idx}`]: val })
                                                                                } else if (!isNaN(val) && val < 0) {
                                                                                    setPesos({ ...pesos, [`${idx}`]: 0 });
                                                                                    setValue("pesos", { ...pesos, [`${idx}`]: val })
                                                                                } else if (!isNaN(val) && val > 1) {
                                                                                    setPesos({ ...pesos, [`${idx}`]: 1 });
                                                                                    setValue("pesos", { ...pesos, [`${idx}`]: val })
                                                                                } else {
                                                                                    setPesos({ ...pesos, [`${idx}`]: null });
                                                                                    setValue("pesos", { ...pesos, [`${idx}`]: val })
                                                                                }
                                                                            }}
                                                                        />);
                                                                    i += 1;
                                                                });
                                                            }
                                                            if (selectedOptions !== null && selectedOptions.filter(e => e.value === 'lcc').length > 0) {
                                                                optionsLCC.forEach(e => {
                                                                    controls.push(
                                                                        <Form.Control className={`mt-1 index-${i}`}
                                                                            min="0.01" max="0.98" step="0.01"
                                                                            type="number" placeholder={e.value}
                                                                            value={pesos[`${i}`] || ''}
                                                                            onChange={(e) => {
                                                                                let idx = 0;
                                                                                e.target.classList.forEach(c => {
                                                                                    if (c.includes('index-')) {
                                                                                        idx = c.split('-')[1];
                                                                                    }
                                                                                });
                                                                                const val = e.target.value;
                                                                                if (!isNaN(val) && val >= 0 && val <= 1) {
                                                                                    setPesos({ ...pesos, [`${idx}`]: val });
                                                                                    setValue("pesos", { ...pesos, [`${idx}`]: val })
                                                                                } else if (!isNaN(val) && val < 0) {
                                                                                    setPesos({ ...pesos, [`${idx}`]: 0 });
                                                                                    setValue("pesos", { ...pesos, [`${idx}`]: val })
                                                                                } else if (!isNaN(val) && val > 1) {
                                                                                    setPesos({ ...pesos, [`${idx}`]: 1 });
                                                                                    setValue("pesos", { ...pesos, [`${idx}`]: val })
                                                                                } else {
                                                                                    setPesos({ ...pesos, [`${idx}`]: null });
                                                                                    setValue("pesos", { ...pesos, [`${idx}`]: val })
                                                                                }
                                                                            }}
                                                                        />);
                                                                    i += 1;
                                                                });

                                                            }
                                                            if (typeof (selectedOptionsSLCA) !== 'undefined' && selectedOptionsSLCA != null && selectedOptionsSLCA.length > 0) {
                                                                selectedOptionsSLCA.forEach(e => {
                                                                    controls.push(
                                                                        <Form.Control className={`mt-1 index-${i}`}
                                                                            step="0.01"
                                                                            type="number" placeholder={e.value}
                                                                            value={pesos[`${i}`] || ''}
                                                                            onChange={(e) => {
                                                                                let idx = 0;
                                                                                e.target.classList.forEach(c => {
                                                                                    if (c.includes('index-')) {
                                                                                        idx = c.split('-')[1];
                                                                                    }
                                                                                });
                                                                                const val = e.target.value;
                                                                                if (!isNaN(val) && val >= 0 && val <= 1) {
                                                                                    setPesos({ ...pesos, [`${idx}`]: val });
                                                                                    setValue("pesos", { ...pesos, [`${idx}`]: val })
                                                                                } else if (!isNaN(val) && val < 0) {
                                                                                    setPesos({ ...pesos, [`${idx}`]: 0 });
                                                                                    setValue("pesos", { ...pesos, [`${idx}`]: val })
                                                                                } else if (!isNaN(val) && val > 1) {
                                                                                    setPesos({ ...pesos, [`${idx}`]: 1 });
                                                                                    setValue("pesos", { ...pesos, [`${idx}`]: val })
                                                                                } else {
                                                                                    setPesos({ ...pesos, [`${idx}`]: null });
                                                                                    setValue("pesos", { ...pesos, [`${idx}`]: val })
                                                                                }
                                                                            }}
                                                                        />);
                                                                    i += 1;
                                                                });
                                                            }
                                                            controls.forEach((control, index) => {
                                                                controlsWithCol.push(
                                                                    <div key={index} className="custom-col" style={{ width: `calc(100%  / ${(controls.length)})` }}>
                                                                        {control}
                                                                    </div>
                                                                );
                                                            });
                                                            return (
                                                                controlsWithCol
                                                            );
                                                        })()}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            {errors.custom_weight_total && (
                                                                <p className="error-message">{errors.custom_weight_total.message}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                        </>
                                    }
                                </>
                            }
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button form="downloadForm" type="submit" className="download" variant="primary">
                        Download <biIcons.BiSolidCloudDownload />
                    </Button>
                    <Button className="close" variant="secondary" onClick={handleCloseDownloadReportModal}>
                        Close <riIcons.RiCloseFill />
                    </Button>
                </Modal.Footer>
            </Modal >
            <div className="header">
                <div className="logo"><img src={logo} alt="Orienting" /></div>
                <div className="menu">
                    <div className="hamburger" onClick={toggleBar}>
                        <giIcons.GiHamburgerMenu />
                    </div>
                </div>
                <div className="header-menu">
                    <User />
                    <div className="download-report" onClick={handleShowDownloadReportModal}><biIcons.BiSolidReport /> Download report</div>
                    <div className="access-oof" onClick={handleShowShowOOFModal}><riIcons.RiFileExcel2Fill /> Access to OOF</div>
                </div>
            </div>
        </>
    )
};